<template>
  <UModal v-model="visible">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
      }"
    >
      <template #header>
        <h3
          class="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Create task type
        </h3>
      </template>
      <UForm ref="form" :schema="schema" :state="formState" @submit="onSubmit">
        <div class="flex items-start gap-2">
          <UFormGroup label="Icon" name="icon" class="w-11">
            <TaskTypeIconPicker
              :default="{
                icon: formState.icon,
                background: formState.background,
              }"
              @change="onChangeIconState"
            >
              <template #default="{ state }">
                <div
                  class="h-8 rounded-md border border-gray-300 w-full flex items-center justify-center"
                >
                  <TaskTypeIcon
                    size="sm"
                    :icon="state.icon"
                    :background="state.background"
                  />
                </div>
              </template>
            </TaskTypeIconPicker>
          </UFormGroup>
          <UFormGroup label="Type name" name="name" class="w-full" required>
            <UInput
              v-model="formState.name"
              class="w-full"
              placeholder="Enter your task type name"
              data-test="task-type-name-input"
            />
          </UFormGroup>
        </div>
        <UFormGroup label="Description" name="description" class="w-full mt-6">
          <UTextarea
            v-model="formState.description"
            class="w-full"
            placeholder="Let people know when to use this type"
          />
        </UFormGroup>
        <div class="flex items-center justify-end mt-6 gap-2">
          <UButton color="gray" variant="ghost" :disabled="loading" @click="visible = false">
            Cancel
          </UButton>
          <UButton type="submit" :loading="loading" data-test="create-task-type-submit-button"> Create task type</UButton>
        </div>
      </UForm>
    </UCard>
  </UModal>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  parentId: string
  source: MorphSource
}>()
const visible = defineModel('visible', {
  type: Boolean,
  default: false,
})
const emit = defineEmits(['created'])

const schema = z.object({
  name: z.string().trim().min(1).max(16),
})
const defaultState = () => ({
  name: '',
  description: '',
  icon: 'heroicons:bug-ant-20-solid',
  background: 'dark-red',
})

const { createTaskType } = useCreateTaskType()

const loading = ref(false)
const form = ref()
const formState = reactive(defaultState())

const onChangeIconState = (state: Record<string, string>) => {
  extend(formState, state)
}

const onSubmit = async (event: FormSubmitEvent<typeof formState>) => {
  loading.value = true
  const data = await createTaskType({
    ...event.data,
    parentId: props.parentId,
    source: props.source,
  })
  loading.value = false
  if (data?.error) {
    return form.value.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  emit('created', event.data)

  // Prevent overwriting the form state when the modal is closed
  sleep(2000).then(() => {
    extend(formState, defaultState())
  })
}
</script>
