<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-full max-w-screen-sm' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
  >
    <template #panel>
      <div class="py-2 max-h-96 scroll-stable overflow-y-auto minimal-scrollbar" data-test="task-type-suggestions">
        <p class="text-sm text-gray-700 font-semibold mb-4 px-3">
          Suggested types
        </p>
        <table class="w-full overflow-auto block">
          <thead class="bg-gray-50 sticky top-0">
            <tr
              class="[&_td]:px-4 [&_td]:py-2 [&_td]:text-xs [&_td]:font-semibold"
            >
              <td class="w-2/4">Name</td>
              <td>Category</td>
              <td class="w-28"></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(suggestion, index) in leftOverSuggestions"
              :key="suggestion.name"
              class="[&_td]:px-5 [&_td]:py-2"
            >
              <td>
                <div class="flex items-start gap-2">
                  <TaskTypeIcon
                    class="mt-1"
                    size="sm"
                    :icon="suggestion.icon"
                    :background="suggestion.background"
                  />
                  <div>
                    <p class="text-sm font-medium">{{ suggestion.name }}</p>
                    <p class="text-xs text-gray-500">{{ suggestion.description }}</p>
                  </div>
                </div>
              </td>
              <td class="text-xs text-gray-500">
                {{ suggestion.categories.join(', ') }}
              </td>
              <td>
                <UButton
                  color="gray"
                  :loading="loading[index]"
                  data-test="add-task-type-suggestion-button"
                  @click="onAddSuggestionTaskType(index)"
                >
                  Add
                </UButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <UDivider />
      <UButton
        size="lg"
        icon="i-heroicons-plus-small"
        variant="ghost"
        color="gray"
        data-test="create-task-type-button"
        class="px-2.5 mx-1 py-1.5 my-1"
        @click="
          () => {
            open = false
            createModalVisible = true
          }
        "
      >
        Create new type
      </UButton>
    </template>
    <slot />
  </UPopover>
  <TaskTypeCreateModal
    v-model:visible="createModalVisible"
    :parent-id="parentId"
    :source="source"
    @created="onCreated"
  />
</template>

<script lang="ts" setup>
import { TaskTypeSuggestions } from '#task-type/constant'
import type { BaseTaskType } from '#task-type/types'

const props = defineProps<{
  parentId: string
  source: MorphSource
  createdTasks: BaseTaskType[]
}>()

const emit = defineEmits(['created'])

const toast = useToast()
const { createTaskType } = useCreateTaskType()

const open = ref(false)
const loading = ref<Record<string, boolean>>({})
const createModalVisible = ref(false)

const leftOverSuggestions = computed(() => {
  return TaskTypeSuggestions.filter((suggestion) => {
    return !props.createdTasks.some((task) => task.name === suggestion.name)
  })
})

const onCreated = (payload: BaseTaskType) => {
  createModalVisible.value = false
  emit('created', payload)
}

const onAddSuggestionTaskType = async (index: number) => {
  const suggestion = leftOverSuggestions.value[index]
  loading.value[index] = true
  const data = await createTaskType({
    name: suggestion.name,
    description: suggestion.description,
    icon: suggestion.icon,
    background: suggestion.background,
    parentId: props.parentId,
    source: props.source,
  })
  loading.value[index] = false
  if (data?.error) {
    const [parseError] = parseGqlErrors(data?.error.graphQLErrors)
    return toast.add({
      title: parseError.message,
      color: 'red',
    })
  }

  open.value = false
  emit('created', suggestion)
}
</script>
