<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-60' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
  >
    <template #panel>
      <div class="grid grid-cols-7 p-2 gap-2">
        <div
          v-for="color in ColorPresets"
          :key="color.name"
          class="size-6 rounded relative cursor-pointer"
          :style="getColorPreset(color.name)"
          @click="onChangeState('background', color.name)"
        >
          <Icon
            v-if="color.name === state.background"
            name="heroicons:check"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div>
      <div class="grid grid-cols-7 p-2 gap-2">
        <div
          v-for="icon in TaskTypeIconPresets"
          :key="icon"
          class="size-6 rounded cursor-pointer flex items-center justify-center"
          :class="{
            'bg-gray-200': icon === state.icon,
          }"
          @click="onChangeState('icon', icon)"
        >
          <Icon class="size-4" :name="icon" />
        </div>
      </div>
    </template>
    <slot :state="state" />
  </UPopover>
</template>

<script lang="ts" setup>
import { TaskTypeIconPresets } from '#task-type/constant'
import { ColorPresets } from '#core/constant'

const props = defineProps({
  default: {
    type: Object as PropType<{ icon: string; background: string }>,
  },
})

const emit = defineEmits(['change'])

const defaultState = () => ({
  icon: '',
  background: '',
  ...props.default,
})

const open = ref(false)
const state = reactive(defaultState())

const onChangeState = (property: keyof typeof state, value: string) => {
  if (state[property] !== value) {
    state[property] = value
    emit('change', state)
  }
}

watch(
  () => open.value,
  (isOpen) => {
    if (isOpen) {
      extend(state, defaultState())
    }
  }
)
</script>
