<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1 h-full"
    :ui="{
      body: {
        base: 'overflow-auto scroll-stable h-full',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="settings-tasks"
        title="Task types & Custom fields"
        :last-page="lastPage"
      />
    </template>
    <div class="flex gap-10">
      <!-- Left side -->
      <div class="flex flex-col min-w-48 w-48">
        <UPopover mode="hover" :popper="{ placement: 'left-start', offsetDistance: 32 }">
          <p class="text-xs font-semibold border-b border-dashed w-fit cursor-pointer hover:border-gray-700">Task types</p>

          <template #panel>
            <div class="bg-gray-50 w-[420px] h-[400px]">
              <img
                src="/assets/images/task-type-explain.jpg"
                width="100%"
                class=""
              />

              <!-- Description Section -->
              <div class="bg-white px-6 py-4 rounded-lg shadow-sm">
                <h2 class="text-xs font-semibold text-gray-900">What are task types?</h2>
                <p class="mt-2 text-gray-500 text-xs">
                  <strong>Module:</strong> is a large body of work that can be broken down into multiple Tasks for easier management.
                </p>
                <p class="mt-2 text-gray-500 text-xs">
                  <strong>Task:</strong> is a specific piece of work that can either stand alone or be part of a Module, helping track progress within the project.
                </p>
                <p class="mt-2 text-gray-500 text-xs">
                  <strong>Subtasks:</strong> are smaller steps within a Task, providing detailed breakdowns for better management.
                </p>
              </div>
            </div>
          </template>
        </UPopover>

        <div class="h-full min-h-1 overflow-y-auto mt-3">
          <TaskTypeSettingsItem
            v-for="taskType in taskTypes.filter(
              (taskType: TaskTypeDetail) => taskType.level === TaskLevel.MODULE
            )"
            :key="taskType.id"
            :task-type="taskType"
            :class="[
              'cursor-pointer rounded-md',
              selectedTaskType?.id === taskType.id
                ? 'bg-gray-100'
                : 'hover:bg-gray-50',
            ]"
            @select="selectTaskType"
          />
          <UDivider class="my-2" />
          <TaskTypeSettingsItem
            v-for="taskType in taskTypes.filter(
              (taskType: TaskTypeDetail) => taskType.level === TaskLevel.TASK
            )"
            :key="taskType.id"
            :class="[
              'cursor-pointer rounded-md',
              selectedTaskType?.id === taskType.id
                ? 'bg-gray-100'
                : 'hover:bg-gray-50',
            ]"
            :task-type="taskType"
            @select="selectTaskType"
          />
          <TaskTypeAddDropdown
            v-if="leftOverSuggestions.length"
            :parent-id="parentId"
            :source="source"
            :created-tasks="taskTypes"
            @created="onCreatedTaskType"
          >
            <UButton
              size="sm"
              color="gray"
              class="w-full p-1.5 mt-0.5"
              icon="i-heroicons-plus-small"
              variant="ghost"
              data-test="add-task-type-button"
            >
              Add type
            </UButton>
          </TaskTypeAddDropdown>
          <UButton
            v-else
            size="sm"
            color="gray"
            class="w-full p-1.5 mt-0.5"
            icon="i-heroicons-plus-small"
            variant="ghost"
            data-test="create-task-type-button"
            @click="() => createModalVisible = true"
          >
            Add type
          </UButton>
          <TaskTypeCreateModal
            v-model:visible="createModalVisible"
            :parent-id="parentId"
            :source="source"
            @created="onCreatedTaskType"
          />
          <UDivider class="my-2" />
          <TaskTypeSettingsItem
            v-for="taskType in taskTypes.filter(
              (taskType: TaskTypeDetail) => taskType.level === TaskLevel.SUBTASK
            )"
            :key="taskType.id"
            :class="[
              'cursor-pointer rounded-md',
              selectedTaskType?.id === taskType.id
                ? 'bg-gray-100'
                : 'hover:bg-gray-50',
            ]"
            :task-type="taskType"
            @select="selectTaskType"
          />
        </div>
      </div>
      <div v-if="selectedTaskType" class="w-full">
        <div class="flex items-center gap-2">
          <TaskTypeIconPicker
            :default="{
              icon: selectedTaskType.icon,
              background: selectedTaskType.background,
            }"
            :disabled="!isTaskTypeEditable"
            @change="onUpdateTaskType"
          >
            <TaskTypeIcon
              size="lg"
              :icon="selectedTaskType.icon"
              :background="selectedTaskType.background"
            />
          </TaskTypeIconPicker>
          <div class="flex justify-between w-full">
            <input
              v-if="isTaskTypeEditable"
              v-model="selectedTaskType.name"
              class="font-semibold outline-none focus:ring-2 ring-primary-500 rounded px-1"
              placeholder="Enter your task type name"
              data-test="task-type-detail-name-input"
              @blur="() => onUpdateTaskType()"
            />
            <p
              v-else
              class="font-semibold outline-none focus:ring-2 ring-primary-500 rounded px-1"
            >
              {{ selectedTaskType.name }}
            </p>
            <UDropdown
              mode="click"
              :open="openQuickActions"
              :items="actionItems"
            >
              <UButton
                v-if="isTaskTypeEditable && taskTypeCount > 1"
                color="gray"
                variant="ghost"
                icon="i-heroicons:ellipsis-horizontal"
                size="sm"
                class="focus:ring-2 ring-primary-500"
                @click.prevent.stop="openQuickActions = !openQuickActions"
              />
              <div class="hidden" />
            </UDropdown>
          </div>
        </div>
        <div class="mt-3">
          <UInput
            v-model="selectedTaskType.description"
            :disabled="!isTaskTypeEditable"
            placeholder="Short description about this type."
            @blur="onUpdateTaskType"
          />
        </div>
        <TaskTypeDropdown
          :ignored-fields="ignoredFields"
          :show-suggested="true"
          @select="(type: FieldType) => (selectedFieldType = type)"
          @attach="onAttachSuggestedField"
          @remove="onRemoveSuggestedField"
        >
          <UButton
            class="border w-full border-gray-400 border-dashed mt-6"
            variant="ghost"
            color="white"
            size="lg"
            icon="i-heroicons-plus-small"
          >
            <span class="text-gray-700" data-test="add-custom-field-button">Add custom field</span>
          </UButton>
        </TaskTypeDropdown>
        <div class="mt-6">
          <FormFieldBox
            v-if="selectedField && FieldFormComponents.has(selectedField.type)"
            :field="selectedField"
            :loading="isCreatingField"
            :task-type-id="selectedTaskType.id"
            @remove="selectedFieldType = undefined"
            @submit="onCreateFieldAndAttach"
            @select="(type: FieldType) => (selectedFieldType = type)"
          />
        </div>
        <div class="flex flex-col gap-2 mt-3">
          <FormFieldBox
            v-for="field in selectedTaskType.fieldsWithPosition"
            :key="field.field.id"
            :field="field.field"
            :task-type-id="selectedTaskType.id"
            :pivot="{
              id: field.id,
              position: field.position,
            }"
            :loading="false"
            collapse
            @submit="
              (data: UpdateFieldParams, form: Record<string, CallableFunction>) => onUpdateField(field.field.id, data, form)
            "
            @remove="onUnattachField(field.field)"
          />
        </div>
      </div>
    </div>
  </UCard>
  <TaskTypeDeleteModal
    v-model:visible="deleteModalVisible"
    :task-type="selectedTaskType!"
    :left-over-task-types="leftOverTaskTypes"
    @close="deleteModalVisible = false"
    @confirm="onDeleteTaskType"
  />
</template>

<script lang="ts" setup>
import { cloneDeep, pick } from 'lodash-es'
import type { TaskTypeDetail, BaseTaskType } from '#task-type/types'
import {
  FieldLists,
  FieldFormComponents,
  type FieldType,
} from '#field/constant'
import type { CreateFieldParams, Field, UpdateFieldParams } from '#field/types'
import { TaskLevel } from '#task/constant'
import type { DropdownItem } from '#ui/types'
import { LazyDeleteModal } from '#components'
import { TaskTypeSuggestions } from '#task-type/constant'

const props = defineProps({
  workspaceId: {
    type: String,
    required: true,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
})

const modal = useModal()
const toast = useToast()
const route = useRoute()
const { result, refetch } = useTaskTypesQuery({
  parentId: props.parentId || route.query.id as string,
  source: props.source,
})
const { load, onResult: onTaskDetailResult } = useTaskTypeLazyQuery('')
const {
  createFieldAndAttach,
  deleteField,
  updateField,
  attachFieldToTaskType,
  unattachFieldFromTaskType
} = useFieldOperation()
const { preventClose } = useBoardSettingsNavigator()
const { updateTaskType } = useUpdateTaskType()
const { deleteTaskType } = useDeleteTaskType()

const selectedFieldType = ref<FieldType>()
const selectedTaskType = ref<TaskTypeDetail>()
const isCreatingField = ref(false)
const deletedFields = ref<string[]>([])
const openQuickActions = ref(false)
const deleteModalVisible = ref(false)
const createModalVisible = ref(false)

const taskTypes = computed<TaskTypeDetail[]>(() => {
  return (result.value?.taskTypes || []).map(taskType => {
    const taskTypeDetail = taskType as TaskTypeDetail
    return {
      ...taskTypeDetail,
      fieldsWithPosition: taskTypeDetail.fieldsWithPosition || []
    }
  })
})

const leftOverSuggestions = computed(() => {
  return TaskTypeSuggestions.filter((suggestion) => {
    return !taskTypes.value.some((task) => task.name === suggestion.name && task.icon === suggestion.icon)
  })
})

const taskTypeCount = computed(
  () =>
    result.value?.taskTypes?.filter(
      (taskType) => taskType.level === TaskLevel.TASK
    ).length || 0
)

const leftOverTaskTypes = computed<TaskTypeDetail[]>(() =>
  taskTypes.value.filter(
    (taskType): taskType is TaskTypeDetail =>
      taskType.level === TaskLevel.TASK &&
      taskType.id !== selectedTaskType.value?.id
  )
)

const selectedField = computed(() =>
  FieldLists.find((field) => field.type === selectedFieldType?.value)
)

const lastPage = computed(() =>
  props.source === 'settings-pack' ? 'settingsPack' : 'index'
)

const ignoredFields = computed(() => {
  return [
    ...(selectedTaskType.value?.fieldsWithPosition?.map(
      (field) => field.field.id
    ) || []),
    ...deletedFields.value,
  ]
})

const isTaskTypeEditable = computed(() => {
  return selectedTaskType.value?.level === TaskLevel.TASK
})

const actionItems = [
  [
    {
      label: 'Delete task type',
      icon: 'i-heroicons-trash-solid',
      iconClass: 'text-red-500',
      class: 'text-red-500',
      click: () => {
        deleteModalVisible.value = true
      },
    },
  ],
] as DropdownItem[][]

onTaskDetailResult((value) => {
  if (value.data?.taskType) {
    const taskType = cloneDeep(value.data.taskType)
    taskType?.fieldsWithPosition.sort(sortPos)
    selectedTaskType.value = taskType
  }
})

const selectTaskType = (id: string) => {
  selectedFieldType.value = undefined
  load(null, {
    id,
  })
}

const onCreateFieldAndAttach = async (
  event: CreateFieldParams,
  form: Record<string, CallableFunction>
) => {
  isCreatingField.value = true
  if (event.name) {
    event.name = event.name.trim().slice(0, 255)
  }

  const response = await createFieldAndAttach(
    {
      ...event,
      workspace: props.workspaceId,
    },
    [selectedTaskType.value?.id] as string[]
  )
  if (!response.success) {
    isCreatingField.value = false
    return form.setErrors(parseGqlErrors(response.error))
  }

  isCreatingField.value = false
  selectedFieldType.value = undefined
}

const onUpdateField = async (
  id: string,
  data: UpdateFieldParams,
  form: Record<string, CallableFunction>
) => {
  const response = await updateField(id, data)
  if (!response.success) {
    return form.setErrors(parseGqlErrors(response.error))
  }

  toast.add({
    title: 'Updated field successfully',
  })
}

const onUnattachField = async (field: Field) => {
  return unattachFieldFromTaskType(field, selectedTaskType.value?.id as string)
}

const onDeleteField = async (id: string) => {
  const { success } = await deleteField(id)
  if (!success) {
    return toast.add({
      title: 'Something went wrong',
      color: 'red',
    })
  }

  deletedFields.value.push(id)

  toast.add({
    title: 'Deleted field successfully',
  })
}

const onAttachSuggestedField = (field: Field) => {
  return attachFieldToTaskType(field, selectedTaskType.value?.id as string)
}

const onRemoveSuggestedField = (field: Field) => {
  preventClose.value = true
  modal.open(LazyDeleteModal, {
    heading: `Delete "${field.name}" field?`,
    content: `Anything stored in this field on any task type in your workspace will be deleted.
    Deleting will take some time to complete, and you may need to refresh the page to see the change.
    You can't undo this.`,
    confirmText: 'Delete field',
    onConfirm: async () => {
      onDeleteField(field.id)
      modal.close()
      preventClose.value = false
    },
    onCancel: () => {
      modal.close()
      preventClose.value = false
    },
  })
}

const onCreatedTaskType = async (payload: BaseTaskType) => {
  await refetch()
  const createdTaskType = taskTypes.value.find(
    (taskType) => taskType.name === payload.name
  )
  if (createdTaskType) {
    selectTaskType(createdTaskType.id)
  }

  createModalVisible.value = false

  toast.add({
    title: 'Created task type successfully',
  })
}

const onUpdateTaskType = (state: Record<string, string> = {}) => {
  if (selectedTaskType.value) {
    extend(selectedTaskType.value, state)
    const taskTypeInput = cloneDeep(selectedTaskType.value)
    const updatedParams = pick(taskTypeInput, [
      'id',
      'name',
      'description',
      'icon',
      'background',
    ])
    return updateTaskType(updatedParams)
  }
}

const onDeleteTaskType = async (replaceTaskTypeId: string) => {
  if (!selectedTaskType.value) return

  const { error } = await deleteTaskType({ id: selectedTaskType.value.id, replaceTaskTypeId })
  if (error) {
    return toast.add({
      title: 'Something went wrong',
      color: 'red',
    })
  }

  deleteModalVisible.value = false
  toast.add({
    title: 'Deleted task type successfully',
  })
}

watch(
  () => taskTypes.value,
  (types) => {
    if (types.length) {
      if (!selectedTaskType.value) {
        const defaultTaskType =
          types.find((type) => type.level === TaskLevel.TASK) ||
          types.find((type) => type.level === TaskLevel.MODULE)

        if (defaultTaskType) {
          selectTaskType(defaultTaskType.id)
        }
      } else if (!types.some((type) => type.id === selectedTaskType.value?.id)) {
        selectTaskType(leftOverTaskTypes.value[0].id)
      }
    }
  },
  {
    immediate: true,
  }
)
</script>
