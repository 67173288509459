<template>
  <UModal v-model="visible">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
      }"
    >
      <template #header>
        <div class="flex items-center">
          <h3
            class="text-base font-semibold leading-6 text-gray-900 dark:text-white"
          >
            Delete "{{ taskType.name }}"?
          </h3>
        </div>
        <span class="text-sm text-gray-500 dark:text-gray-400">
          Before delete this task type, you need to re-assign all task currently under "{{ taskType.name }}" to another task type.
        </span>
      </template>
      <div class="mt-3">
        <span class="text-sm font-medium text-gray-700 dark:text-gray-400">Replace "{{ taskType.name }}" with</span>
        <UInputMenu
          v-model="selectedTaskType"
          :options="leftOverTaskTypes"
          option-attribute="name"
          size="md"
        >
          <template #leading>
            <TaskTypeIcon
              :background="selectedTaskType.background"
              :icon="selectedTaskType.icon"
              size="sm"
            />
          </template>
        </UInputMenu>
      </div>
      <div class="mt-3 flex justify-end">
        <UButton
          color="gray"
          class="mr-2"
          @click="handleCloseModal"
        >
          Cancel
        </UButton>
        <UButton color="red" @click="handleRemove">Delete {{ taskType.name }}</UButton>
      </div>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { TaskTypeDetail } from '#task-type/types'

const props = defineProps({
  taskType: {
    type: Object as PropType<TaskTypeDetail>,
    required: true,
  },
  leftOverTaskTypes: {
    type: Array as PropType<TaskTypeDetail[]>,
    required: true,
  },
})

const visible = defineModel('visible', {
  type: Boolean,
  default: false,
})

const emit = defineEmits(['close', 'confirm'])

const selectedTaskType = ref<TaskTypeDetail>(props.leftOverTaskTypes[0])

const handleCloseModal = () => {
  emit('close')
}

const handleRemove = async () => {
  // handle api call
  emit('confirm', selectedTaskType.value.id)
}

watch(() => visible.value, (value) => {
  if (value) {
    selectedTaskType.value = props.leftOverTaskTypes[0]
  }
})
</script>
